body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  background-color: black;
  font-size: 12;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@charset "utf-8";
/* CSS Document */

body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  background-color: black;
}

.M_Log {
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 5px;
  font-size: 12px;
}

.ev_body {
  margin-top: -10px;
}

.SUF {
  color: #ffffff;
}

.F_B {
  color: #ffb400;
}
.NYYC {
  color: #f00;
}
.UC {
  color: #ff0;
}
.STP {
  color: #0c0;
}
.APPL {
  color: #fefefe;
}
.UNIT_0 {
  color: #0c0;
  text-decoration: blink;
  /* font-size: 30px; */
}
.UNIT_5 {
  color: #ff0;
  /* font-size: 30px; */
}
.UNIT_15 {
  color: #f00;
  /* font-size: 30px; */
}
.UNIT_CANCEL {
  text-decoration: line-through;
}
.E_M {
  color: #0ff;
  /* font-size: 32.5px; */
}
.FM {
  color: #f6f;
  font-size: 50.5px;
}
.blink_on {
  color: #000 !important;
}

.clock {
  margin-right: 1%;
  margin-top: 1%;
  text-align: right;
  width: 100px;
  float: right;
  transition: -transform 0.2s ease-out;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  -moz-transition: -moz-transform 0.2s ease-out;
  -o-transition: -o-transform 0.2s ease-out;
  -ms-transition: -ms-transform 0.2s ease-out;
}

.update {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.paging {
  margin-left: 1%;
}

.light {
  background-color: #282c34;
}

@-webkit-keyframes alert-pulse {
  to {
    background-color: rgb(255, 255, 0, 0.8);
  }
}

@keyframes alert-pulse {
  to {
    background-color: rgb(255, 255, 0, 0.8);
  }
}

.alert {
  -webkit-animation-name: alert-pulse;
          animation-name: alert-pulse;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

