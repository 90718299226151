@charset "utf-8";
/* CSS Document */

body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  background-color: black;
}

.M_Log {
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 5px;
  font-size: 12px;
}

.ev_body {
  margin-top: -10px;
}

.SUF {
  color: #ffffff;
}

.F_B {
  color: #ffb400;
}
.NYYC {
  color: #f00;
}
.UC {
  color: #ff0;
}
.STP {
  color: #0c0;
}
.APPL {
  color: #fefefe;
}
.UNIT_0 {
  color: #0c0;
  text-decoration: blink;
  /* font-size: 30px; */
}
.UNIT_5 {
  color: #ff0;
  /* font-size: 30px; */
}
.UNIT_15 {
  color: #f00;
  /* font-size: 30px; */
}
.UNIT_CANCEL {
  text-decoration: line-through;
}
.E_M {
  color: #0ff;
  /* font-size: 32.5px; */
}
.FM {
  color: #f6f;
  font-size: 50.5px;
}
.blink_on {
  color: #000 !important;
}

.clock {
  margin-right: 1%;
  margin-top: 1%;
  text-align: right;
  width: 100px;
  float: right;
  transition: -transform 0.2s ease-out;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  -moz-transition: -moz-transform 0.2s ease-out;
  -o-transition: -o-transform 0.2s ease-out;
  -ms-transition: -ms-transform 0.2s ease-out;
}

.update {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}

.paging {
  margin-left: 1%;
}

.light {
  background-color: #282c34;
}

@keyframes alert-pulse {
  to {
    background-color: rgb(255, 255, 0, 0.8);
  }
}

.alert {
  animation-name: alert-pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
